<!--
 * @Descripttion: 我的 / 用户中心
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-05 10:32:47
-->
<template>
	<div class="vh bg ">
		<div class="pad flex-between">
			<div class="flex">
				<img :src="orgPath" class="wh20">
				<div class="mar-l2" style="flex:1">
					<h3 class="van-multi-ellipsis--l2">{{org.departName}}</h3>
					<p class="role" v-if="userInfo.adminFlag=='1'">管理员</p>
					<p class="role" v-if="userInfo.adminFlag!='1'&&userInfo.operatorFlag=='1'">操作员</p>
					<p class="gray2" v-if="org.serviceStatus=='01'||org.serviceStatus=='03'">{{org.expireDate}}到期</p>
					<p class="gray2" v-if="org.serviceStatus==null||org.serviceStatus=='00'||org.serviceStatus=='02'">暂未开通专家库抽取服务</p>
				</div>
			</div>
			<h3 class="goldBg b5 self-align-start" style="flex-shrink:0" @click="$router.push('/renew')">
				{{ (org.serviceStatus=='01'||org.serviceStatus=='03') ? '去续费' : '开通服务'}}
			</h3>
		</div>

		<div class="pad-row">
			<div class="whiteBg">
				<div @click="$router.push('/expert')">
					<h3>{{org.expertNum}}</h3>
					<p>专家数</p>
				</div>
				<div @click="$router.push('/record')">
					<h3>{{org.projectNum}}</h3>
					<p>项目数</p>
				</div>
				<div>
					<h3>{{org.extractNum}}</h3>
					<p>抽取项目数</p>
				</div>
			</div>
		</div>
		<div class="tt">
			<ul>
				<router-link tag="li" :key="item.text" v-for="item in list" class="flex-between mar-t2" :to="{path : item.routerPath}">
					<div>
						<i :class="item.icon"></i>
						<h3 class="f45 inline mar-l2">{{item.text}}</h3>
					</div>
					<i class="wh08 icon-arrow"></i>
				</router-link>
			</ul>
		</div>

		<expert-footer :activeIndex="activeIndex"></expert-footer>
	</div>
</template>

<script>
	import expertFooter from '@/components/Footer.vue'
	export default {
		data() {
			return {
				activeIndex: '2',
				list: [{
						icon: 'wh10 icon-company',
						text: '公司信息',
						routerPath: '/company'
					},
					{
						icon: 'wh10 icon-review',
						text: '评审专业',
						routerPath: '/profession'
					},
					{
						icon: 'wh10 icon-expert',
						text: '专家管理',
						routerPath: '/expert'
					},
					{
						icon: 'wh10 icon-record',
						text: '项目记录',
						routerPath: '/record'
					},
					{
						icon: 'wh10 icon-advise',
						text: '意见反馈',
						routerPath: '/advise'
					},
				],
				org: {
					id: '',
					expertNum: 0,
					projectNum: 0,
					extractNum: 0,
					surplusDays: 0,
					departName: '',
					serviceStatus: '00'
				},
				userInfo: {
					adminFlag: '1',
					operatorFlag: '0',
					sex: '0'
				},
				maleSrc: '/static/img/icon/male.png',
				femaleSrc: '/static/img/icon/female.png',
				orgPath: '',
				workweixin: false,				//企业微信
				openUserid:	"",
			}
		},
		components: {
			expertFooter
		},
		created() {
			//企微微信标志
			this.workweixin = this.$ls.get("expert_workweixin_flag")&&"1"==this.$ls.get("expert_workweixin_flag")
			if(this.workweixin){
				this.openUserid = this.$ls.get("expert_workweixin_user").open_userid
			}
			//
			if (this.$ls.get("edCurrentOrg")) {
				this.org.id = this.$ls.get("edCurrentOrg").id;
				this.org.departName = this.$ls.get("edCurrentOrg").departName;
				this.orgPath = this.$ls.get("edCurrentOrg").orgPic ? this.$ls.get("edCurrentOrg").orgPic :
					'/static/img/icon/default-logo.png'
			}
			//获取公司信息
			this.getOrgInfo();
			if (this.$ls.get("edCurrentUser")) {
				this.userInfo.adminFlag = this.$ls.get("edCurrentUser").adminFlag;
				this.userInfo.operatorFlag = this.$ls.get("edCurrentUser").operatorFlag;
				this.userInfo.sex = this.$ls.get("edCurrentUser").userExternal.sex;
				console.log(this.userInfo.adminFlag);
				if (this.userInfo.adminFlag == '1') {
					let obj = {
						icon: 'wh10 icon-usr',
						text: '操作员管理',
						routerPath: '/account'
					};
					this.list.push(obj);
				}
			}
			//获取用户扩展推广信息表
			if (this.$ls.get("edCurrentUser") != null && this.$ls.get("edCurrentUser").userPromote) {
				let obj = {
					icon: 'wh10 icon-xc',
					text: '宣传推广',
					routerPath: '/publicity'
				};
				this.list.push(obj);
			}
			/** 若用户处于试用期或已开通服务或者开通服务后服务到期都可显示短信服务菜单 */
			if (this.$ls.get('edCurrentOrgExternal') &&
				this.$ls.get('edCurrentOrgExternal').serviceStatus &&
				this.$ls.get('edCurrentOrgExternal').serviceStatus != '00') {
				this.list.push({
					icon: 'wh10 icon-msg',
					text: '短信服务',
					routerPath: '/smsrecharge'
				})
			}
			// this.list.push({
			//   icon: 'wh10 icon-thanks',
			//   text: '特别鸣谢',
			//   routerPath: '/thanks'
			// })
		},
		methods: {
			//获取公司信息
			getOrgInfo() {
				let params = new URLSearchParams()
				params.append("id", this.org.id)
				params.append("openid", this.workweixin?this.openUserid:this.$ls.get("openid"))
				this.$ajax({
					methods: 'get',
					url: '/gateway/ed/wx/mineController/queryById/',
					headers: {
						'X-Access-Token': this.$ls.get("token")
					},
					params: params,
				}).then(res => {
					if (res.data.success) {
						this.org = res.data.result;
						this.orgPath = this.org.orgPic || '/static/img/icon/default-logo.png'
						this.$ls.set("edOrgInfo", this.org, 10 * 60 * 1000)
						this.$ls.set("edCurrentOrgExternal", this.org)
					} else {
						this.$toast.fail("失败")
						console.log(res.data.message)
					}
				});
			}
		}
	}
</script>

<style scoped>
	.whiteBg {
		background-color: white;
		border-radius: 0.2rem;
		display: flex;
		padding: 0.3rem 0;
	}

	.whiteBg>div {
		width: 33.33%;
		position: relative;
	}

	.whiteBg>div:nth-child(2)::before,
	.whiteBg>div:nth-child(2)::after {
		content: '';
		height: 1rem;
		width: 1px;
		position: absolute;
		top: 0.2rem;
		background-color: #E6E6E6;
	}

	.whiteBg>div:nth-child(2)::before {
		left: 0;
	}

	.whiteBg>div:nth-child(2)::after {
		right: 0;
	}

	.whiteBg h3 {
		font-size: 0.6rem;
		text-align: center;
	}

	.whiteBg p {
		font-size: 0.3rem;
		color: #737373;
		text-align: center;
	}

	.mar-t2 {
		margin-top: 0.3rem;
		height: 1.2rem;
	}

	.tt {
		background-color: white;
		padding: 0.3rem 0.5rem;
		margin-top: 0.2rem
	}

	.self-align-start {
		width: 1.8rem;
		font-size: 0.35rem;
		padding: 0.05rem 0.1rem;
		text-align: center;
		font-weight: bold;
	}
</style>
